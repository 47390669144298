<template>
  <div class="bloc-small p-1">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ContainerBloc",
  computed: {},
  props: {
    text: {
      type: String,
      required: false
    }
  }
};
</script>
<style>
.bloc-small {
  min-width: 13rem;
  min-height: 10rem;
}
</style>