<template>
  <div class="wrapper">
    <div class="manager" v-if="!loadingGet">
      <div class="liste" v-show="!selected && !newClient">
        <div class="pb-1">
          <div class="d-flex justify-content-between align-items-center pt-2">
            <h5 class="mb-0 ml-1 mr-1">Liste des clients</h5>
            <button-circle name="plus" size="18" v-tooltip="'Ajouter un client'" @click="clearForm"></button-circle>
          </div>
          <BaseInput type="search" v-model="search" inputText="Filtre" class modeIOS />
        </div>
        <div class="scroll-y rounded">
          <div>
            <div v-if="!search && compte_id > 0">
              <div class v-for="c in clients" :key="c.id">
                <div
                  class="d-flex justify-content-between align-items-center border-bottom cursor-pointer p-2"
                  v-if="c.client_compte_id === compte_id"
                >
                  <div>{{ c.client_nom }} {{ c.client_prenom }}</div>
                  <div class="d-flex">
                    <button-circle
                      @click="showForm(c.id)"
                      name="edit"
                      size="16"
                      v-tooltip="'modifier le client'"
                    ></button-circle>
                    <button-circle
                      @click="select(c)"
                      name="check"
                      size="16"
                      v-tooltip="'sélectionner le client'"
                    ></button-circle>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!search && compte_id < 1">
              <div class="p-2 border-bottom cursor-pointer" v-for="c in clients" :key="c.id">
                <div class="d-flex justify-content-between align-items-center">
                  <div>{{ c.client_nom }} {{ c.client_prenom }}</div>
                  <div class="d-flex">
                    <button-circle
                      @click="showForm(c.id)"
                      name="edit"
                      size="16"
                      v-tooltip="'modifier le client'"
                    ></button-circle>
                    <button-circle
                      @click="select(c)"
                      name="check"
                      size="16"
                      v-tooltip="'sélectionner le client'"
                    ></button-circle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="search">
            <div class="p-2 border-bottom cursor-pointer" v-for="c in filteredItems" :key="c.id">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{ c.client_nom }} {{ c.client_prenom }}</div>
                <div class="d-flex">
                  <button-circle
                    @click="showForm(c.id)"
                    name="edit"
                    size="16"
                    v-tooltip="'modifier le client'"
                  ></button-circle>
                  <button-circle
                    @click="select(c)"
                    name="check"
                    size="16"
                    v-tooltip="'sélectionner le client'"
                  ></button-circle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form" v-if="selected">
        <base-form backBtn @back="hideForm" @click="updateClient" :loadingSend="loadingSend">
          <base-form-group dense icon="briefcase">
            <base-form-row row>
              <base-input v-model="client_nom" inputText="Nom" :errors="feedback.client_nom"></base-input>
              <base-input v-model="client_prenom" inputText="Prénom" :errors="feedback.client_prenom"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Compte"
                :value="compte_id"
                @input="client_compte_id = compte_id"
                :options="comptes"
                :errors="feedback.client_compte_id"
              ></base-select>
              <base-select
                inputText="Type"
                v-model.number="client_type_id"
                :options="data.types"
                :errors="feedback.client_type_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Région"
                v-model.number="client_region_id"
                :options="data.regions"
                :errors="feedback.client_region_id"
              ></base-select>
              <base-select
                inputText="Site"
                v-model.number="client_site_id"
                :options="data.sites"
                :errors="feedback.client_site_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-input v-model="client_poste" inputText="Poste" :errors="feedback.client_poste"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input v-model="client_mail1" inputText="Mail 1" :errors="feedback.client_mail1"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input v-model="client_tel1" inputText="tel1" :errors="feedback.client_tel1"></base-input>
            </base-form-row>
          </base-form-group>
        </base-form>
      </div>
      <div class="form" v-if="newClient">
        <base-form backBtn @back="hideForm" @click="addClient(newClientData)" :loadingSend="loadingSend">
          <base-form-group dense icon="briefcase">
            <base-form-row row>
              <base-input v-model="newClientData.client_nom" inputText="Nom" :errors="feedback.client_nom"></base-input>
              <base-input
                v-model="newClientData.client_prenom"
                inputText="Prénom"
                :errors="feedback.client_prenom"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Compte"
                :value="compte_id"
                @input="client_compte_id = compte_id"
                :options="comptes"
                :errors="feedback.client_compte_id"
              ></base-select>
              <base-select
                inputText="Type"
                v-model.number="newClientData.client_type_id"
                :options="data.types"
                :errors="feedback.client_type_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="newClientData.client_poste"
                inputText="Poste"
                :errors="feedback.client_poste"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="newClientData.client_mail1"
                inputText="Mail 1"
                :errors="feedback.client_mail1"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="newClientData.client_tel1"
                inputText="tel1"
                :errors="feedback.client_tel1"
              ></base-input>
            </base-form-row>
          </base-form-group>
        </base-form>
      </div>
    </div>
    <loader-bloc class="m-5 scroll-y" v-if="loadingGet"></loader-bloc>
  </div>
</template>
<script>
import { errHandler } from "@/helpers.js";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { filterItems } from "@/helpers.js";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
//import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import LoaderBloc from "@/components/bases/LoaderBloc.vue";

export default {
  components: {
    BaseInput,
    BaseSelect,
    //BaseInputArea,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    ButtonCircle,
    LoaderBloc,
  },
  props: ["compte_id"],
  data() {
    return {
      loadingGet: false,
      loadingSend: false,
      selected: false,
      newClient: false,
      newClientData: {},
      feedback: {},
      search: "",
    };
  },
  methods: {
    ...mapActions({
      getClients: "clients/getClients",
      getComptes: "comptes/getComptes",
      getData: "clients/getData",
    }),
    select: function (client) {
      this.$emit("select", client);
    },
    showForm: function (id) {
      this.selected = true;
      this.$store.dispatch("clients/getClient", id);
    },
    hideForm: function () {
      this.selected = false;
      this.newClient = false;
    },
    clearForm: function () {
      this.newClient = true;
      this.$store.commit("clients/setClient", {});
    },
    addClient: function () {
      this.newClientData.client_compte_id = this.compte_id;
      this.loadingSend = true;
      this.$store
        .dispatch("clients/create", this.newClientData)
        .then((client) => this.$emit("select", client))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingSend = false));
    },
    updateClient: function () {
      let data = {
        id: this.id,
        client_compte_id: this.compte_id,
        client_nom: this.client_nom,
        client_prenom: this.client_prenom,
        client_type_id: this.client_type_id,
        client_region_id: this.client_region_id,
        client_site_id: this.client_site_id,
        client_poste: this.client_poste,
        client_service: this.client_service,
        client_adresse: this.client_adresse,
        client_mail1: this.client_mail1,
        client_mail2: this.client_mail2,
        client_mail3: this.client_mail3,
        client_tel1: this.client_tel1,
        client_tel2: this.client_tel2,
        client_tel3: this.client_tel3,
        client_tel_fax: this.client_tel_fax,
        client_commentaire: this.client_commentaire,
      };
      this.loadingSend = true;
      this.$store
        .dispatch("clients/update", data)
        .then((client) => this.$emit("select", client))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingSend = false));
    },
  },
  computed: {
    ...mapGetters({
      clients: "clients/clients",
      comptes: "comptes/comptes",
      data: "clients/data",
    }),
    ...mapFields("clients", [
      "client.id",
      "client.client_type_id",
      "client.client_client_id",
      "client.client_compte_id",
      "client.client_site_id",
      "client.client_region_id",
      "client.client_pilote_id",
      "client.client_nom",
      "client.client_prenom",
      "client.client_poste",
      "client.client_service",
      "client.client_adresse",
      "client.client_mail1",
      "client.client_mail2",
      "client.client_mail3",
      "client.client_tel1",
      "client.client_tel2",
      "client.client_tel3",
      "client.client_tel_fax",
      "client.client_commentaire",
    ]),
    filteredItems() {
      return filterItems(this.clients, "client_nom", this.search);
    },
  },
  mounted() {
    this.loadingGet = true;
    this.getData();
    this.getComptes().then(() => {
      this.getClients().then(() => {
        this.loadingGet = false;
      });
    });
  },
};
</script>
<style lang="css" scoped>
.wrapper {
  min-height: 50vh;
}
.scroll-y {
  height: 60vh;
  overflow-y: auto;
}
.max-w-400 {
  max-width: 280px;
}
</style>
