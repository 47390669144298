<template>
  <div v-if="user && user.id === userId" class="d-flex align-items-center cursor-pointer">
    <app-avatar :url="user.collaborateur_avatar" propSize="2.4rem" class="mr-2"></app-avatar>
    <div v-if="showText">{{ user.collaborateur_nom }} {{ user.collaborateur_prenom }}</div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  components: {
    AppAvatar,
  },
  data() {
    return {
      tab: "infos",
      competences: {},
      showForm: false,
    };
  },
  props: {
    userId: {
      Type: Number,
      Requierd: true,
    },
    loading: {
      Type: Boolean,
      Requierd: false,
      default: false,
    },
    showText: {
      Type: Boolean,
      Requierd: false,
      default: true,
    },
  },
  mounted() {},
  computed: {
    ...mapMultiRowFields("user", ["users"]),
    user() {
      return this.users.find((x) => x.id === this.userId);
    },
  },
  methods: {},
};
</script>
<style lang="css">
.tab-item {
  color: #fff;
  padding: 1px;
  cursor: pointer;
}

.tab-item.active {
  color: #007bff;
}
</style>
